<template>
  <div class="about_inner">
    <div class="left">
      <div class="image">
        <img src="../../assets/img/cosi/cosi3.png" alt="placeholder" />
        <div
          class="main"
          v-scroll-reveal="{ delay: 20 }"
          :style="{ backgroundImage: 'url(' + aboutImg + ')' }"
        ></div>
      </div>
      <!-- End image  -->
    </div>
    <!-- End left -->

    <div class="right">
      <div class="shane_tm_title">
        <h3 v-scroll-reveal="{ delay: 40 }">
          Cosi fan tutte
        </h3>
      </div>
      <div class="text" v-scroll-reveal="{ delay: 60 }">
        <p>
          Presented by Opera By Request <br />
          March 17th <br />
          7:30 PM <br />
          College Street United <br />
          452 College St, Toronto, Ontario <br /> <br />
        </p>
        <p>
          "Cosi fan tutte" -- women are like that… but only because men make them. Don Alfonso, looking to make a quick buck, bets his friends Ferrando and Guglielmo that their fiancés will be unfaithful if pushed towards temptation. The fiancés, Fiordilidgi and Dorabella, are put to the test through a series of costumed visitors and various schemes. Their maid, Despina, works in conjunction with Don Alfonso to ensure she gets a cut of the wager. Will the women stay true to their betrothed? Care to make a bet?
        </p>
      </div>
      <div class="shane_tm_button">
        <a href="https://myevent.com/obr-cosi-2023" target="_blank" download>
          Buy Tickets Here
        </a>
      </div>
    </div>
    <!-- End right -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      aboutImg: require("../../assets/img/cosi/cosi3.png")
    };
  },
};
</script>

<style lang="scss" scoped>
.image {
  height: 700px !important;
}
</style>
