<template>
  <div class="home-four">
    <Header />
    <Slider />
    <!-- End Slider Section -->

    <div class="shane_tm_section" id="about">
      <div class="shane_tm_about">
        <div class="container">
          <About />
        </div>
        <!--  End container -->
      </div>
    </div>

    <div class="shane_tm_section" id="cast">
      <div class="shane_tm_portfolio">
        <Cast />
      </div>
    </div>
    <!-- End About Section -->
    <Footer />
    <!-- End  footer  -->
  </div>
</template>

<script>
import Header from "../../components/CosiHeader";
import Footer from "../../components/Footer";
import Slider from "../../components/slider/CosiSliderFour";
import Cast from "../../components/portfolio/CosiCast";
import About from "../../components/about/CosiAbout";

export default {
  components: {
    Header,
    Footer,
    Slider,
    Cast,
    About,
  },
};
</script>

<style lang="scss" scoped></style>
